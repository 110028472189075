import React, { useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import DefaultLayout from '../../layouts/DefaultLayout'
import { applyParamsToUrlPath, downloadFile } from '../../utils'
import { UserDTO } from '../../dtos/UserDTO'
import { UserFilters } from '../../components/UserFilters'
import TableWrapper from '../../layouts/TableWrapper'
import useCredentials from '../../hooks/useCredentials'
import { usePagination } from '../../hooks/usePagination'
import { ActionsCell } from '../../components/ActionsCell'
import UploadMembers from './UploadMembers'

const Members: React.FC = (): JSX.Element => {
  const { credentials, hasEditPermission } = useCredentials()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [members, setMembers] = useState<Array<UserDTO>>([])
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber, filters, applyFilters } = usePagination()

  const loadMembers = useCallback(async (): Promise<void> => {
    try {
      setError('')
      const res = await axios.get(applyParamsToUrlPath({ page: currentPageNumber, ...filters }, '/api/panel/members'), {
        headers: credentials,
      })
      setMembers(res.data.members)
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, filters, setTotalPageCount])

  useEffect(() => {
    loadMembers()
  }, [loadMembers, currentPageNumber, filters])

  const downloadMembers = async () => {
    try {
      const res = await axios.get(
        applyParamsToUrlPath({ page: currentPageNumber, ...filters }, '/api/panel/members/export_csv'),
        {
          headers: credentials,
        }
      )
      downloadFile(res.data, 'members')
    } catch (e) {
      setError(e?.message)
      console.error(e.message)
    }
  }

  return (
    <DefaultLayout>
      <TableWrapper
        title='Members'
        loading={loading}
        isError={!!error}
        errorMessage={error}
        isEmpty={!members?.length}
        pagination={{
          totalPageCount,
          currentPage: currentPageNumber,
          onChangePage: setPageNumber,
        }}
        actions={
          hasEditPermission && (
            <div className='mb-4 is-flex is-justify-content-flex-end'>
              <button onClick={downloadMembers} className='button mr-2' type='button'>
                Download CSV
              </button>
              <UploadMembers loadMembers={loadMembers} />
              <Link to='/panel/members/new' className='button is-link'>
                New
              </Link>
            </div>
          )
        }
      >
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <UserFilters applyFilters={applyFilters} />
            </tr>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>First name</th>
              <th>Last name</th>
              <th>Activation status</th>
              <th>Organization</th>
              <th>Sub users</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <tr key={member.id} className='is-clickable' onClick={() => history.push(`/panel/members/${member.id}`)}>
                <th>{member.id}</th>
                <td>{member.email}</td>
                <td>{member.first_name}</td>
                <td>{member.last_name}</td>
                <td>{member.activation_status || '-'}</td>
                <td>{member.organization_name}</td>
                <td>
                  {member.children_count || 0}/{member.max_sub_account || 0}
                </td>
                <td>
                  <ActionsCell>
                    {hasEditPermission && (
                      <Link to={`/panel/members/${member.id}/edit`} className='button is-text'>
                        Edit
                      </Link>
                    )}
                  </ActionsCell>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </DefaultLayout>
  )
}

export default Members
